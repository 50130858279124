<template>
    <a
        class="list-row"
        :class="{ 'mx-0 px-0': contained }"
    >
        <span>{{ title }}</span>
        <div class="right">
            <div class="switch">
                <input
                    :disabled="disabled"
                    type="checkbox"
                    id="switch"
                    :checked="value"
                    @change="$emit('change', $event.target.checked)"
                >
                <label
                    :class="{ 'bg-secondary': disabled }"
                    v-show="!submitting"
                    for="switch"
                />
                <span
                    v-show="submitting"
                    class="spinner-border spinner-border-sm"
                />
            </div>
        </div>
    </a>
</template>

<script>
    export default {
        name: 'ListRowToggle',
        props: {
            title: {
                type: String,
                required: true,
            },
            value: {
                type: Boolean,
                default: false,
            },
            submitting: {
                type: Boolean,
                default: false,
            },
            contained: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/components/list-row';
    @import '../styles/components/switch';
</style>
