<template>
    <div>
        <page-header
            :title="$t('pages.AvenPrimeSettings.title')"
            :show-back="true"
            @onClose="$router.go(-1)"
            :error-text="errorText"
        />

        <list-row-toggle
            :disabled="!isPrimaryCard"
            :title="$t('pages.AvenPrimeSettings.toggle')"
            v-model="isPrimeDesired"
            :submitting="submitting"
            @change="changeProduct"
        />

        <div
            class="text-muted small mx-3 mt-1 mb-3"
            v-if="!isPrimaryCard"
        >
            Only the primary cardholder can change these settings
        </div>

        <div
            class="alert text-start mt-2 mx-3"
            v-if="isPrimeStatusConflictingOnBackend"
            :class="{ 'alert-success': isPrimeDesiredOnBackend, 'alert-warning': !isPrimeDesiredOnBackend }"
            role="alert"
        >
            <p v-html="productChangeText" />
        </div>
    </div>
</template>

<script>
    import PageHeader from '@/components/PageHeader'
    import ListRowToggle from '@/components/ListRowToggle'
    import { mapGetters } from 'vuex'
    import { ProductCategory } from '@/store'
    import generic from '@/utils/generic'
    import { postDesiredProduct } from '@/services/api'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import format from '@/mixins/format'

    export default {
        name: 'AvenPrimeSettings',
        components: {
            PageHeader,
            ListRowToggle,
        },
        mixins: [format],
        data() {
            return {
                loading: false,
                submitting: false,
                error: false,
                errorText: '',
                isPrimeDesired: false,
                productChangeText: '',
            }
        },
        computed: {
            ...mapGetters(['desiredProductCategory', 'productCategory', 'loanTermOptions', 'nextStatementDate', 'isPrimaryCard']),
            isPrimeStatusConflictingOnBackend: function () {
                return this.desiredProductCategory !== this.productCategory
            },
            isPrimeDesiredOnBackend: function () {
                return this.desiredProductCategory === ProductCategory.prime
            },
        },
        mounted: async function () {
            this.$logEvent('view_aven_prime_settings')
            this.isPrimeDesired = this.desiredProductCategory === ProductCategory.prime
            this.setProductChangeText()
        },
        watch: {
            isPrimeStatusConflictingOnBackend: function () {
                this.isPrimeDesired = this.desiredProductCategory === ProductCategory.prime
                this.setProductChangeText()
            },
            isPrimeDesiredOnBackend: function () {
                this.isPrimeDesired = this.desiredProductCategory === ProductCategory.prime
                this.setProductChangeText()
            },
        },
        methods: {
            setProductChangeText: function () {
                this.$nextTick(() => {
                    if (!this.loanTermOptions?.length) {
                        return
                    }
                    const dateText = generic.utcToTimeZone(this.nextStatementDate, undefined, 'MMM D')

                    const basicLoanTerms = this.loanTermOptions.find((terms) => terms.product === ProductCategory.basic)
                    const primeLoanTerms = this.loanTermOptions.find((terms) => terms.product === ProductCategory.prime)

                    const basicApr = this.formatApr(basicLoanTerms.apr / 100)
                    const primeApr = this.formatApr(primeLoanTerms.apr / 100)

                    const basicCashback = this.toFormattedAprStripTrailingZerosAfterDecimalPoint(basicLoanTerms.cashBack)
                    const primeCashback = this.toFormattedAprStripTrailingZerosAfterDecimalPoint(primeLoanTerms.cashBack)

                    const basicMonthlyFee = this.toFormattedUSD(basicLoanTerms.monthlyFee)
                    const primeMonthlyFee = this.toFormattedUSD(primeLoanTerms.monthlyFee)

                    if (this.desiredProductCategory !== ProductCategory.prime && this.productCategory === ProductCategory.prime) {
                        const newTermsText = `<strong>New Terms Starting ${dateText}</strong><br>- ${basicApr} Variable APR<br>- ${basicCashback} Cashback<br>- ${basicMonthlyFee} Monthly Fee`
                        this.productChangeText = `Aven Prime is set to turn off <strong>starting ${dateText}</strong>. Current reward points will be redeemed as statement credit on your next statement.<br><br>${newTermsText}`
                    } else if (this.desiredProductCategory === ProductCategory.prime && this.productCategory !== ProductCategory.prime) {
                        const newTermsText = `<strong>New Terms Starting ${dateText}</strong><br>- ${primeApr} Variable APR<br>- ${primeCashback} Cashback<br>- ${primeMonthlyFee} Monthly Fee`
                        this.productChangeText = `Aven Prime is set to turn on <strong>starting ${dateText}</strong>. Current reward points will be redeemed as statement credit on your next statement.<br><br>${newTermsText}`
                    } else {
                        this.productChangeText = ''
                    }
                })
            },
            changeProduct: async function (newIsPrimeDesiredStatus) {
                if (this.submitting) return
                this.submitting = true
                const newProduct = newIsPrimeDesiredStatus ? ProductCategory.prime : ProductCategory.basic
                try {
                    await postDesiredProduct(newProduct)
                    this.isPrimeDesired = newIsPrimeDesiredStatus
                    await this.$store.dispatch('updateAccountOverview')
                } catch (e) {
                    this.errorText = ApiErrorHandler(e)
                }
                this.submitting = false
            },
        },
    }
</script>

<style lang="scss" scoped></style>
